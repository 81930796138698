@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Bold.ttf');
  font-weight: bold; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Heavy.ttf');
  font-weight: 900; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-HeavyItalic.ttf');
  font-weight: 900; 
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Light.ttf');
  font-weight: 200; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-LightItalic.ttf');
  font-weight: 200; 
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Medium.ttf');
  font-weight: 500; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-MediumItalic.ttf');
  font-weight: 500; 
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Regular.ttf');
  font-weight: 400; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-RegularItalic.ttf');
  font-weight: 400; 
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Semibold.ttf');
  font-weight: 600; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-SemiboldItalic.ttf');
  font-weight: 600; 
  font-style: italic;
}


@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Medium.eot');
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
      url('./assets/fonts/SFPRO/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Medium.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Medium.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Heavy.eot');
  src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
      url('./assets/fonts/SFPRO/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Heavy.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Heavy.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.eot');
  src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.eot');
  src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Thin.eot');
  src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
      url('./assets/fonts/SFPRO/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Thin.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Thin.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Light.eot');
  src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
      url('./assets/fonts/SFPRO/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Light.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Light.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.eot');
  src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Bold.eot');
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
      url('./assets/fonts/SFPRO/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Bold.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Bold.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Black.eot');
  src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
      url('./assets/fonts/SFPRO/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Black.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Black.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.eot');
  src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.eot');
  src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
      url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.eot');
  src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.eot');
  src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.eot');
  src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Semibold.eot');
  src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
      url('./assets/fonts/SFPRO/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Semibold.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Semibold.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.eot');
  src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
      url('./assets/fonts/SFPRO/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Regular.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Regular.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.eot');
  src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: "SF Pro Text";
}